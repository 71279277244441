<template>
  <div class="page-layout about-page wave-filigrana" v-if="pageData && pageData.attributes">

    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container " v-if="pageData.attributes.field_page_main_content">
      
      <div class="page-body blue-title" v-html="this.pageData.attributes.field_page_main_content.value"></div>

      <div v-if="pageData.field_attachments.length">
        <!-- <p v-if="pageData.field_attachments.length" class="label">ATTACHMENTS</p> -->
        <h4 class="blue">Attachments</h4>
        <a v-for="(att, i) in pageData.field_attachments" :key="'att'+i"
          class="btn download-link slim bold plain" :href="endpoint + att.attributes.uri.url" download
          target="_blank">
          <span class="download icon-right" v-if="pageData.relationships.field_attachments.data[i].meta.description != '' ">
            {{pageData.relationships.field_attachments.data[i].meta.description}}
          </span>

          <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
        </a>
      </div>

      <br>
      <br>

      <div v-if="pageData.attributes.field_external_link.length">
        <h4 class="blue">External Links</h4>
        <a v-for="(link, i) in pageData.attributes.field_external_link" :key="'link'+i"
          class="btn border slim plain" :href="link.uri" target="_blank">
            <span class="external icon-right" v-if="link.title != '' ">
              {{ link.title }}
            </span>

            <span v-else class="external icon-right" style="word-break: break-all;">{{ link.uri }}</span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'about',
  components: {
    HeaderIntroImage,
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "	Qualification Profiles for Quantum Technologies",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: "3c1586a9-c5ca-4e76-bca8-6e68de9e926c"
    }
  },
  computed: {
    endpoint() {
      return process.env.VUE_APP_ENDPOINT
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    blockimg() {
      return require("../assets/block_img_1.jpg")
    }
  },
  methods: {
    goto() {
      this.$router.push('/get-involved')
    }
  },
  mounted() {
    fetchSinglePage(this.currentPageID, {
      include: ['field_attachments']
    })
    .then(res => {
      this.pageData = res
     //console.log(this.pageData)
    })
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
  .about-page {
    padding-bottom: 20vh;
    position: relative;
  }
</style>